import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Container,
  Image,
  Nav,
  Row,
  Form,
  Dropdown,
  Accordion,
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import "../../../../Assets/css/MegaMenu.css";
import logoImage from "../../../../Assets/images/logo.svg";
import SearchIcon from "../../../../Assets/images/icon/search.svg";
import PointerIcon from "../../../../Assets/images/icon/pointer.svg";
import ContactIcon from "../../../../Assets/images/icon/contactUs.svg";
import AppoitmentIcon from "../../../../Assets/images/icon/appoitment.svg";
import WishlistIcon from "../../../../Assets/images/icon/wishlist.svg";
import BagIcon from "../../../../Assets/images/icon/bag.svg";
import Login from "../../../../Components/Login";
import { useHistory } from "react-router-dom";
import { Drawer } from "@mui/material";
import menuImages from "../../../../Assets/images/icon/hemburger.svg";
import {
  Email_details,
  client_name,
  contact_number,
} from "../../../../Helpers/request";
import { isDesktop, isMobile, isMobileOnly, isTablet } from "react-device-detect";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { BsFillChatFill, BsFillTelephoneFill } from "react-icons/bs";
import { IoMdMail, IoMdContact, } from "react-icons/io";
import { MdDateRange } from "react-icons/md";

import { img_url } from "../../../../Helpers/request";
import HeaderStyle from "../Assets/megamenu.module.css";
import {
  currencyCode,
  currencyFlag,
  currencyIcon,
  loginDrawer,
} from "../../../../Reducer/homeReducer";
import { RiWhatsappFill } from "react-icons/ri";

const MegaMenu = (props) => {


  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (window.location.pathname == "/our-story" && window.location.search == "?srsltid=AfmBOopvFJc4J5XxHaJd_230nXiaTwSX1glxFmXCoJR1In6ZJTxFbtzB") {
      history.push('/')
    }
  }, [])
  const [setDesktopSearch, setShowDesktopSearch] = useState(false);
  const [submenu, setSubmenu] = useState([]);
  const [show, setShow] = useState(false);
  const [topHeader, setTopHeader] = useState(true);
  const [left, setLeft] = useState(false);
  const [hover, setHover] = useState(0);
  const [search_text, setSearch_text] = useState("");
  const menudata = useSelector((state) => state.persistedReducer.home.menu);
  const currencycode = useSelector((state) => state.persistedReducer.home.currencycode);
  const currencyflag = useSelector((state) => state.persistedReducer.home.currencyflag);
  const currencydata = useSelector((state) => state.persistedReducer.home.currency);
  const user = useSelector(
    (state) => state.persistedReducer.home.userData
  );
  useEffect(() => {
    document.body.className = window.location.pathname;
    return () => {
      document.body.className = "";
    };
  }, [window.location.pathname]);

  const [
    isScrollValueMoreThanHeaderHeight,
    setIsScrollValueMoreThanHeaderHeight,
  ] = useState(false);

  const cartlength = useSelector(
    (state) => state.persistedReducer.cart.cartlength
  );
  const wishlistlength = useSelector(
    (state) => state.persistedReducer.wish.whishlistlength
  );
  const toggleDrawer = (open) => {
    setLeft(open);
  };

  useEffect(() => {
    setTimeout(() => {
      const addGoogleTranslateScript = () => {
        if (!document.querySelector("script[src='//translate.google.com/translate_a/element.js']")) {
          const script = document.createElement("script");
          script.src = "//translate.google.com/translate_a/element.js?cb=initGoogleTranslate";
          script.async = true;
          document.body.appendChild(script);
        }
      };
      window.initGoogleTranslate = () => {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "en", // Set your original page language
            includedLanguages: "en,es", // Languages to include in the dropdown
            autoDisplay: true,
          },
          "google_translate_element"
        );

      };
      addGoogleTranslateScript();
    }, 1000);

  }, []);

  useEffect(() => {
    // googleTranslateElementInit()
    const handleScroll = () => {
      // console.log(window.scrollY);
      setIsScrollValueMoreThanHeaderHeight(window.scrollY > 20);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: "en",
  //       includedLanguages: "en,es,fr,de,iw,ja,zh-CN,sv,tr,ar,hi",
  //       gaTrack: true,
  //     },
  //     "google_translate_element"
  //   );
  // };

  const currencyHandle = async (code, img, icon) => {
    await dispatch(currencyIcon(icon));
    await dispatch(currencyCode(code));
    await dispatch(currencyFlag(img));
    localStorage.removeItem("bw-fancycolordata");
    localStorage.removeItem("bw-gemstonedata");
    localStorage.removeItem("bw-diamonddata");
    localStorage.removeItem("bw-settingdata");
    sessionStorage.removeItem("bw-listfilter");
    sessionStorage.removeItem("bw_f_filter");
    sessionStorage.removeItem("bw_s_filter");
    sessionStorage.removeItem("bw_d_filter");
    sessionStorage.removeItem("bw_g_filter");
    sessionStorage.removeItem("bw-list-category");
    if (window.location.pathname == "/complete-diamond-ring") {
      history.push("/diamonds");
    } else if (window.location.pathname == "/complete-gemstone-ring") {
      history.push("/gemstones");
    } else if (window.location.pathname == "/complete-fancycolor-ring") {
      history.push("/fancy-color-diamond");
    }
    window.location.reload();
  };
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const resizeScreen = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    resizeScreen();
    window.addEventListener("resize", resizeScreen);
    return () => {
      window.removeEventListener("resize", resizeScreen);
    };
  });

  const divRef = useRef(null);
  const [divHeight, setDivHeight] = useState(0);

  useEffect(() => {
    if (menudata.length > 0 && divRef.current) {
      setDivHeight(divRef.current.offsetHeight);
    }
  }, [menudata]);

  return (
    <>
      <div className="rcs_header_seaction" id="rcs_header_seaction">
        <div
          style={
            screenWidth > 1439
              ? { height: "130px" }
              : screenWidth > 1279
                ? { height: "120px" }
                : screenWidth > 1023
                  ? { height: "110px" }
                  : screenWidth > 768
                    ? { height: "120px" }
                    : screenWidth < 576
                      ? { height: "100px" }
                      : { height: "100%" }
          }
        >
          <div ref={divRef}
            className={
              isScrollValueMoreThanHeaderHeight
                ? "rcs_logo_head rcs_sticky-top sticky-top"
                : "rcs_logo_head"
            }
          >
            {/* Header Section */}
            <section className="rcs_header_bar d-none d-lg-block">
              <div className="rcs_header_top_bar">
                <div className="icon_section">
                  <Image
                    onClick={() =>
                      setShowDesktopSearch(!setDesktopSearch)
                    }
                    src={SearchIcon}
                    alt="Search icon"
                    width="16px"
                     loading="lazy"
                  ></Image>

                  <div
                    className={
                      setDesktopSearch == true
                        ? "show " +
                        " rcs_search_bar " +
                        HeaderStyle.DesktopSearch
                        : "close " +
                        " rcs_search_bar " +
                        HeaderStyle.DesktopSearch
                    }
                  >
                    {setDesktopSearch && (
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          history.push("/search/" + search_text);
                          setSearch_text("");
                          window.scrollTo(0, 0);
                        }}
                      >

                        <Form.Control
                          type="text"
                          placeholder="Search for.."
                          value={search_text}
                          onChange={(e) => {
                            setSearch_text(e.target.value)
                          }
                          }
                        />
                        <AiOutlineClose
                          className={
                            HeaderStyle.DesktopSearchClose
                          }
                          onClick={() =>
                            setShowDesktopSearch(false)
                          }
                        />
                      </Form>
                    )}
                  </div>
                  <a href="https://www.google.com/maps/dir//Manuel+Spinosa+Jewellery+Av.+Miguel+Cano,+7+29602+Marbella+M%C3%A1laga,+Spain/@36.5081545,-4.8868923,1812m/data=!3m1!1e3!4m8!4m7!1m0!1m5!1m1!1s0xd7327f8eb2ad063:0x8289ca7266c413bc!2m2!1d-4.8868679!2d36.5082192?entry=ttu&g_ep=EgoyMDI0MTIwOC4wIKXMDSoASAFQAw%3D%3D" target="_blank">
                    <Image
                      src={PointerIcon}
                      alt="Pointer icon"
                      width="16px"
                       loading="lazy"
                    ></Image>
                  </a>
                  <span className="d-flex align-items-center cursor-pointer"
                    onClick={() => {
                      history.push("/contact");
                    }}>
                    <Image
                      src={ContactIcon}
                      alt="Contact us icon"
                      width="16px"
                       loading="lazy"
                    ></Image>
                    <span> Contact Us </span>
                  </span>
                </div>

                <div className="icon_section">
                  {
                    screenWidth > 990 && <div id="google_translate_element" className='float-right'></div>
                  }

                  <span className="d-flex align-items-center cursor-pointer"
                    onClick={() => {
                      history.push("/make-an-appointment");
                    }}
                  >
                    <Image
                      src={AppoitmentIcon}
                      alt="Appoitment icon"
                      width="16px"
                       loading="lazy"
                    ></Image>
                    <span> Book an Appointment </span>
                  </span>

                  <Login />

                  <span className="position-relative"
                    onClick={() =>
                      user?.isLogin
                        ? history.push("/account/wishlist")
                        : history.push("/wishlist")
                    }>
                    <Image
                      src={WishlistIcon}
                      alt="Shopping Bag icon"
                      width="16px"
                       loading="lazy"
                    ></Image>
                    {wishlistlength && wishlistlength != "0" ? (
                      <span className="rcs_wish_badge">
                        {wishlistlength}
                      </span>
                    ) : (
                      ""
                    )}
                  </span>

                  <span className="position-relative">
                    <Image
                      onClick={() => {
                        history.push("/cart");
                      }}
                      src={BagIcon}
                      alt="Bag icon"
                      width="16px"
                       loading="lazy"
                    >

                    </Image>
                    {cartlength && cartlength != "0" ? (
                      <span className="rcs_cart_badge">
                        {cartlength}
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </div>
            </section>
            {isMobile ?
              <>

              </> : ''}
            <div className="rcs_header">
              <Container fluid className="p-0">
                {/* Center Header */}
                <div className={HeaderStyle.showcase_center_header}>
                  <div className="d-flex align-items-center justify-content-between">
                    {isMobile ? (
                      <>
                        <Nav className="rcs_mobile_header_menu">
                          <Image
                            onClick={() => {
                              toggleDrawer(true);
                              dispatch(loginDrawer(false));
                            }}
                            src={menuImages}
                            alt="menu icon"
                            width="30px"
                            className=""
                             loading="lazy"
                          ></Image>

                          <Image
                            className="brand-logo img-fluid"
                            onClick={() => history.push("/")}
                            src={logoImage}
                            alt={client_name.c_name}
                             loading="lazy"
                          ></Image>
                        </Nav>

                      </>
                    ) : (
                      <Col md={4} className="d-flex align-items-center">
                        {/* <div
                          className={HeaderStyle.showcase_center_header_left}
                        >
                          <ul>
                            <li
                              onClick={(e) => {
                                window.location.href = contact_number.link;
                              }}
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9.60946 10.3936C12.9337 13.7169 13.6878 9.87218 15.8044 11.9873C17.8449 14.0272 19.0177 14.4359 16.4324 17.0205C16.1085 17.2808 14.051 20.4118 6.82018 13.183C-0.411557 5.95325 2.71766 3.89362 2.97798 3.56988C5.56956 0.978123 5.97123 2.15774 8.01175 4.19769C10.1283 6.31366 6.28522 7.07026 9.60946 10.3936Z"
                                  fill="var(--primary)"
                                />
                              </svg>
                              {contact_number.text}
                            </li>
                            <li
                              onClick={(e) => {
                                window.location.href = Email_details.link;
                              }}
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M14.1162 2.5C15.2337 2.5 16.3087 2.94167 17.0995 3.73417C17.8912 4.525 18.3337 5.59167 18.3337 6.70833V13.2917C18.3337 15.6167 16.442 17.5 14.1162 17.5H5.88366C3.55783 17.5 1.66699 15.6167 1.66699 13.2917V6.70833C1.66699 4.38333 3.54949 2.5 5.88366 2.5H14.1162ZM15.442 7.95L15.5087 7.88333C15.7078 7.64167 15.7078 7.29167 15.4995 7.05C15.3837 6.92583 15.2245 6.85 15.0587 6.83333C14.8837 6.82417 14.717 6.88333 14.5912 7L10.8337 10C10.3503 10.4008 9.65783 10.4008 9.16699 10L5.41699 7C5.15783 6.80833 4.79949 6.83333 4.58366 7.05833C4.35866 7.28333 4.33366 7.64167 4.52449 7.89167L4.63366 8L8.42533 10.9583C8.89199 11.325 9.45783 11.525 10.0503 11.525C10.6412 11.525 11.217 11.325 11.6828 10.9583L15.442 7.95Z"
                                  fill="var(--primary)"
                                />
                              </svg>
                              {Email_details.text}
                            </li>
                          </ul>
                        </div> */}
                      </Col>
                    )}
                    {/* <Col
                      xs={5}
                      md={4}
                      className="d-flex align-items-center justify-content-center bg-dark"
                    >

                    </Col> */}

                    {isMobile ? (

                      <div className="rcs_right_side rcs_right_side_mobile1">
                        <div className="icon_section">
                          <Login />
                          <span className="position-relative mx-0"
                            onClick={() =>
                              user?.isLogin
                                ? history.push("/account/wishlist")
                                : history.push("/wishlist")
                            }>
                            <Image
                              src={WishlistIcon}
                              alt="Shopping Bag icon"
                              width="16px"
                               loading="lazy"
                            ></Image>
                            {wishlistlength && wishlistlength != "0" ? (
                              <span className="rcs_wish_badge">
                                {wishlistlength}
                              </span>
                            ) : (
                              ""
                            )}
                          </span>

                          <span className="position-relative mx-0">
                            <Image
                              onClick={() => {
                                history.push("/cart");
                              }}
                              src={BagIcon}
                              alt="Bag icon"
                              width="16px"
                               loading="lazy"
                            >

                            </Image>
                            {cartlength && cartlength != "0" ? (
                              <span className="rcs_cart_badge">
                                {cartlength}
                              </span>
                            ) : (
                              ""
                            )}
                          </span>

                        </div>
                        {/* <ul>
                            <li>
                              <div
                                className="showacase_mobile_login  position-relative"
                                onClick={() => toggleDrawer(false)}
                              >
                                <div
                                  className="d-flex justify-content-start align-items-center"
                                  onClick={() =>
                                    user?.isLogin
                                      ? history.push("/account/wishlist")
                                      : history.push("/wishlist")
                                  }
                                >
                                  <svg
                                    className="rcs_cart_mobile_icon1"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M15.8498 2.50065C16.4808 2.50065 17.1108 2.58965 17.7098 2.79065C21.4008 3.99065 22.7308 8.04065 21.6198 11.5806C20.9898 13.3896 19.9598 15.0406 18.6108 16.3896C16.6798 18.2596 14.5608 19.9196 12.2798 21.3496L12.0298 21.5006L11.7698 21.3396C9.48077 19.9196 7.34977 18.2596 5.40077 16.3796C4.06077 15.0306 3.02977 13.3896 2.38977 11.5806C1.25977 8.04065 2.58977 3.99065 6.32077 2.76965C6.61077 2.66965 6.90977 2.59965 7.20977 2.56065H7.32977C7.61077 2.51965 7.88977 2.50065 8.16977 2.50065H8.27977C8.90977 2.51965 9.51977 2.62965 10.1108 2.83065H10.1698C10.2098 2.84965 10.2398 2.87065 10.2598 2.88965C10.4808 2.96065 10.6898 3.04065 10.8898 3.15065L11.2698 3.32065C11.3616 3.36962 11.4647 3.44445 11.5537 3.50912C11.6102 3.55009 11.661 3.58699 11.6998 3.61065C11.7161 3.62028 11.7327 3.62996 11.7494 3.63972C11.8351 3.68977 11.9245 3.74191 11.9998 3.79965C13.1108 2.95065 14.4598 2.49065 15.8498 2.50065ZM18.5098 9.70065C18.9198 9.68965 19.2698 9.36065 19.2998 8.93965V8.82065C19.3298 7.41965 18.4808 6.15065 17.1898 5.66065C16.7798 5.51965 16.3298 5.74065 16.1798 6.16065C16.0398 6.58065 16.2598 7.04065 16.6798 7.18965C17.3208 7.42965 17.7498 8.06065 17.7498 8.75965V8.79065C17.7308 9.01965 17.7998 9.24065 17.9398 9.41065C18.0798 9.58065 18.2898 9.67965 18.5098 9.70065Z"
                                      fill="var(--primary)"
                                    />
                                  </svg>
                                  {wishlistlength && wishlistlength != "0" ? (
                                    <span className="rcs_wish_badge new-mob-wish">
                                      {wishlistlength}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </li>
                            <li
                              onClick={() => {
                                history.push("/cart");
                              }}
                            >
                              <svg
                                className="rcs_cart_mobile_icon"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M14.1213 11.2331H16.8891C17.3088 11.2331 17.6386 10.8861 17.6386 10.4677C17.6386 10.0391 17.3088 9.70236 16.8891 9.70236H14.1213C13.7016 9.70236 13.3719 10.0391 13.3719 10.4677C13.3719 10.8861 13.7016 11.2331 14.1213 11.2331ZM20.1766 5.92749C20.7861 5.92749 21.1858 6.1418 21.5855 6.61123C21.9852 7.08067 22.0551 7.7542 21.9652 8.36549L21.0159 15.06C20.8361 16.3469 19.7569 17.2949 18.4879 17.2949H7.58639C6.25742 17.2949 5.15828 16.255 5.04837 14.908L4.12908 3.7834L2.62026 3.51807C2.22057 3.44664 1.94079 3.04864 2.01073 2.64043C2.08068 2.22305 2.47038 1.94649 2.88006 2.00874L5.2632 2.3751C5.60293 2.43735 5.85274 2.72207 5.88272 3.06905L6.07257 5.35499C6.10254 5.68257 6.36234 5.92749 6.68209 5.92749H20.1766ZM7.42604 18.9079C6.5867 18.9079 5.90723 19.6018 5.90723 20.459C5.90723 21.306 6.5867 22 7.42604 22C8.2554 22 8.93487 21.306 8.93487 20.459C8.93487 19.6018 8.2554 18.9079 7.42604 18.9079ZM18.6673 18.9079C17.8279 18.9079 17.1484 19.6018 17.1484 20.459C17.1484 21.306 17.8279 22 18.6673 22C19.4966 22 20.1761 21.306 20.1761 20.459C20.1761 19.6018 19.4966 18.9079 18.6673 18.9079Z"
                                  fill="var(--primary)"
                                />
                              </svg>
                          
                              {cartlength && cartlength != "0" ? (
                                <span className="rcs_cart_badge">
                                  {cartlength}
                                </span>
                              ) : (
                                ""
                              )}
                            </li>
                            <li>
                              <Login />
                            </li>
                          </ul> */}
                      </div>

                    ) : isTablet ? (
                      <Col>
                        <div className="rcs_right_side rcs_right_side_mobile1">
                          <ul>
                            <li>
                              <Login />
                            </li>
                            <li
                              onClick={() => {
                                history.push("/cart");
                              }}
                            >
                              <svg
                                className="rcs_cart_mobile_icon"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M14.1213 11.2331H16.8891C17.3088 11.2331 17.6386 10.8861 17.6386 10.4677C17.6386 10.0391 17.3088 9.70236 16.8891 9.70236H14.1213C13.7016 9.70236 13.3719 10.0391 13.3719 10.4677C13.3719 10.8861 13.7016 11.2331 14.1213 11.2331ZM20.1766 5.92749C20.7861 5.92749 21.1858 6.1418 21.5855 6.61123C21.9852 7.08067 22.0551 7.7542 21.9652 8.36549L21.0159 15.06C20.8361 16.3469 19.7569 17.2949 18.4879 17.2949H7.58639C6.25742 17.2949 5.15828 16.255 5.04837 14.908L4.12908 3.7834L2.62026 3.51807C2.22057 3.44664 1.94079 3.04864 2.01073 2.64043C2.08068 2.22305 2.47038 1.94649 2.88006 2.00874L5.2632 2.3751C5.60293 2.43735 5.85274 2.72207 5.88272 3.06905L6.07257 5.35499C6.10254 5.68257 6.36234 5.92749 6.68209 5.92749H20.1766ZM7.42604 18.9079C6.5867 18.9079 5.90723 19.6018 5.90723 20.459C5.90723 21.306 6.5867 22 7.42604 22C8.2554 22 8.93487 21.306 8.93487 20.459C8.93487 19.6018 8.2554 18.9079 7.42604 18.9079ZM18.6673 18.9079C17.8279 18.9079 17.1484 19.6018 17.1484 20.459C17.1484 21.306 17.8279 22 18.6673 22C19.4966 22 20.1761 21.306 20.1761 20.459C20.1761 19.6018 19.4966 18.9079 18.6673 18.9079Z"
                                  fill="var(--primary)"
                                />
                              </svg>
                              {/* <MdOutlineShoppingBag className="rcs_cart_mobile_icon" /> */}
                              {cartlength && cartlength != "0" ? (
                                <span className="rcs_cart_badge">
                                  {cartlength}
                                </span>
                              ) : (
                                ""
                              )}
                            </li>
                          </ul>
                        </div>
                      </Col>
                    ) : (
                      <Col
                        md={4}
                        className="d-flex align-items-center justify-content-end"
                      >
                        {/* <div
                          className={
                            "mg_menu_right_side_area " +
                            HeaderStyle.showcase_center_header_right
                          }
                        >
                          <div className="rcs_right_side rcs_right_side_mobile d-none d-lg-block">
                            <ul>
                              <li
                                className="d-none d-lg-block"
                              >
                                         
                                {wishlistlength && wishlistlength !== "0" ? (
                                  <span className="rcs_wish_badge">
                                    {wishlistlength}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </li>
                              <li
                                className="d-none d-lg-block"
                                onClick={() => {
                                  history.push("/cart");
                                }}
                              >
                             

                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M14.1213 11.2331H16.8891C17.3088 11.2331 17.6386 10.8861 17.6386 10.4677C17.6386 10.0391 17.3088 9.70236 16.8891 9.70236H14.1213C13.7016 9.70236 13.3719 10.0391 13.3719 10.4677C13.3719 10.8861 13.7016 11.2331 14.1213 11.2331ZM20.1766 5.92749C20.7861 5.92749 21.1858 6.1418 21.5855 6.61123C21.9852 7.08067 22.0551 7.7542 21.9652 8.36549L21.0159 15.06C20.8361 16.3469 19.7569 17.2949 18.4879 17.2949H7.58639C6.25742 17.2949 5.15828 16.255 5.04837 14.908L4.12908 3.7834L2.62026 3.51807C2.22057 3.44664 1.94079 3.04864 2.01073 2.64043C2.08068 2.22305 2.47038 1.94649 2.88006 2.00874L5.2632 2.3751C5.60293 2.43735 5.85274 2.72207 5.88272 3.06905L6.07257 5.35499C6.10254 5.68257 6.36234 5.92749 6.68209 5.92749H20.1766ZM7.42604 18.9079C6.5867 18.9079 5.90723 19.6018 5.90723 20.459C5.90723 21.306 6.5867 22 7.42604 22C8.2554 22 8.93487 21.306 8.93487 20.459C8.93487 19.6018 8.2554 18.9079 7.42604 18.9079ZM18.6673 18.9079C17.8279 18.9079 17.1484 19.6018 17.1484 20.459C17.1484 21.306 17.8279 22 18.6673 22C19.4966 22 20.1761 21.306 20.1761 20.459C20.1761 19.6018 19.4966 18.9079 18.6673 18.9079Z"
                                    fill="var(--primary)"
                                  />
                                </svg>

                                {cartlength && cartlength != "0" ? (
                                  <span className="rcs_cart_badge">
                                    {cartlength}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </li>
                            <li>
                                <Dropdown
                                  className={
                                    "d-inline rcs_dropdown_lang " +
                                    HeaderStyle.showcase_new_lang
                                  }
                                >
                                  <Dropdown.Toggle id="dropdown-autoclose-true">
                                    <Image
                                      src={currencyflag}
                                      alt={currencycode}
                                    ></Image>
                                    <span>{currencycode}</span>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {currencydata?.map((val) => (
                                      <Dropdown.Item
                                        onClick={() => {
                                          currencyHandle(
                                            val.currency_code,
                                            val.country_flag,
                                            val.currency_icon
                                          );
                                        }}
                                        href="#"
                                      >
                                        <Image
                                          src={val.country_flag}
                                          alt={val.currency_code}
                                        ></Image>
                                        <span> {val.currency_code}</span>
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </li> 
                            </ul>
                          </div>
                        </div> */}
                      </Col>
                    )}

                    {isMobile ? (
                      <Col xs={12} className="d-none">
                        <div className="rcs_search_bar mobileSearchBox">
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              history.push("/search/" + search_text);
                              window.scrollTo(0, 0);
                              // setSearch_text("");

                            }}
                          >
                            {/* <SearchIcon
                          onClick={(e) => {
                            history.push("/search/" + search_text);
                            setSearch_text("");
                            
                          }}
                        /> */}
                            <svg
                              onClick={(e) => {
                                history.push("/search/" + search_text);
                                setSearch_text("");

                              }}
                              width="16"
                              height="16"
                              style={{ opacity: "0.3" }}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.7112 18.3373L15.2347 13.8554C16.3899 12.3855 17.0878 10.5542 17.0878 8.55422C17.0878 3.83133 13.2611 0 8.54392 0C3.82671 0 0 3.83133 0 8.55422C0 13.2771 3.82671 17.1084 8.54392 17.1084C10.5656 17.1084 12.4188 16.4096 13.8628 15.2289L18.3393 19.7108C18.5319 19.9036 18.7726 20 19.0132 20C19.2539 20 19.4946 19.9036 19.7112 19.7108C20.0963 19.3253 20.0963 18.7229 19.7112 18.3373ZM1.92539 8.55422C1.92539 4.89157 4.88568 1.92771 8.54392 1.92771C12.2022 1.92771 15.1625 4.89157 15.1625 8.55422C15.1625 12.2169 12.2022 15.1807 8.54392 15.1807C4.88568 15.1807 1.92539 12.2169 1.92539 8.55422Z"
                                fill="var(--primary)"
                              ></path>
                            </svg>
                            <Form.Control
                              type="text"
                              placeholder="Search for.."
                              value={search_text}
                              onChange={(e) => setSearch_text(e.target.value)}
                            />
                            {/* <AiOutlineClose
                          onClick={() => setSearch_show(!search_show)}
                        /> */}
                          </Form>
                        </div>

                      </Col>
                    ) : (
                      ""
                    )}

                  </div>
                  <div className="mobile_search_bar d-lg-none">
                    <div className="rcs_search_bar mobileSearchBox px-2 mb-0">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          history.push("/search/" + search_text);
                          window.scrollTo(0, 0);
                          setSearch_text("");

                          toggleDrawer(false);
                        }}
                      >
                        {/* <SearchIcon
                          onClick={(e) => {
                            history.push("/search/" + search_text);
                            setSearch_text("");
                            
                          }}
                        /> */}
                        <svg
                          onClick={(e) => {
                            history.push("/search/" + search_text);
                            setSearch_text("");

                          }}
                          width="16"
                          height="16"
                          style={{ opacity: "0.3" }}
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19.7112 18.3373L15.2347 13.8554C16.3899 12.3855 17.0878 10.5542 17.0878 8.55422C17.0878 3.83133 13.2611 0 8.54392 0C3.82671 0 0 3.83133 0 8.55422C0 13.2771 3.82671 17.1084 8.54392 17.1084C10.5656 17.1084 12.4188 16.4096 13.8628 15.2289L18.3393 19.7108C18.5319 19.9036 18.7726 20 19.0132 20C19.2539 20 19.4946 19.9036 19.7112 19.7108C20.0963 19.3253 20.0963 18.7229 19.7112 18.3373ZM1.92539 8.55422C1.92539 4.89157 4.88568 1.92771 8.54392 1.92771C12.2022 1.92771 15.1625 4.89157 15.1625 8.55422C15.1625 12.2169 12.2022 15.1807 8.54392 15.1807C4.88568 15.1807 1.92539 12.2169 1.92539 8.55422Z"
                            fill="var(--primary)"
                          ></path>
                        </svg>
                        <Form.Control
                          type="text"
                          placeholder="Search for.."
                          value={search_text}
                          onChange={(e) => setSearch_text(e.target.value)}
                        />
                        {/* <AiOutlineClose
                          onClick={() => setSearch_show(!search_show)}
                        /> */}
                      </Form>
                    </div>
                  </div>
                </div>

                <>
                  <div className={HeaderStyle.showcase_bottom_header}>
                    {/* Menu Section */}
                    <div className="rcs_menu menu_list_main">
                      <Container fluid className="rcs_custome_container1">
                        <Row>
                          <nav id="rcs_menu_header_top">

                            <div className="rcs_logo_section text-center">
                              <Image
                                onClick={() => history.push("/")}
                                src={logoImage}
                                alt={client_name.c_name}
                                 loading="lazy"
                              ></Image>
                            </div>

                            <ul className="rcs_header_menu">
                              {menudata?.map((value) => (
                                <li
                                  className={
                                    "rcs_top_header_menu " +
                                    HeaderStyle.showcase_top_header_menu
                                  }
                                  onMouseEnter={() => {
                                    setHover(1);
                                    setSubmenu(value);
                                  }}
                                  onMouseLeave={() => setHover(0)}
                                >
                                  <h6 className="1st">
                                    <NavLink to={value?.slug}>
                                      {value?.name}
                                    </NavLink>
                                  </h6>
                                  {value?.design == 5 ? (
                                    <ul
                                      className="rcs_top_header_menu_dropdown"
                                      onMouseEnter={() => setHover(1)}
                                      onMouseLeave={() => setHover(0)}
                                    >
                                      {submenu?.submenu?.map((val) => (
                                        <li className="dir rcs_top_header_menu1">
                                          <span>
                                            <NavLink to={val?.slug}>
                                              {val.name}
                                            </NavLink>
                                          </span>
                                        </li>
                                      ))}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              ))}
                            </ul>
                          </nav>
                        </Row>
                      </Container>
                    </div>
                  </div>
                  {/*mega menu */}
                  <div className="menu_item_main">
                    {submenu?.design != 5 ? (
                      <div
                        onMouseEnter={() => setHover(1)}
                        onMouseLeave={() => setHover(0)}
                        className={
                          hover == "1" && submenu?.submenu?.length
                            ? "rcs_top_header_menu_dropdown mega_menu_content_main visible bw-" +
                            submenu?.name?.replaceAll(" ", "-")
                            : "rcs_top_header_menu_dropdown mega_menu_content_main invisible"
                        }
                      >
                        <div className="rcs_megaMenu d-block">
                          <div className="rcs_megaMenu_inner_top">
                            <div className="rcs_megaMenu_inner">
                              <Container fluid className="p-0">
                                <Row className="w-100 m-auto  justify-content-center">
                                  {submenu?.submenu?.map((val, index) => (
                                    <>
                                      <Col
                                        lg={val?.col}
                                        xl={val?.col}
                                        className="pl-0"
                                      >
                                        {val?.submenu?.map((val1) =>
                                          val1?.design == "1" ? (
                                            <div
                                              className={
                                                "rcs_diamonds_content mj_daimond_d_font bw-" +
                                                val1?.name?.replaceAll(" ", "-")
                                              }
                                            >
                                              <div
                                                className={
                                                  "mega-list-cat " +
                                                  HeaderStyle.megaListCat
                                                }
                                              >
                                                <h2 className="title14 font-bold text-uppercase mtb">
                                                  {val1?.name}
                                                </h2>
                                                <ul>
                                                  {val1?.submenu?.map(
                                                    (val2) => (
                                                      <li>
                                                        {val2?.design == 4 ? (
                                                          <Link
                                                            to="#"
                                                            onClick={() =>
                                                              window.open(
                                                                img_url +
                                                                val2?.slug,
                                                                "_target"
                                                              )
                                                            }
                                                          >
                                                            {val2?.img_url
                                                              ?.length ? (
                                                              <img
                                                                src={
                                                                  val2?.img_url
                                                                }
                                                                className="img-size"
                                                                alt={
                                                                  val2?.alt_tag

                                                                }
                                                                loading="lazy"
                                                              />
                                                            ) : (
                                                              ""
                                                            )}
                                                            {val2?.name}
                                                          </Link>
                                                        ) : (
                                                          <Link to={val2?.slug}>
                                                            {val2?.img_url
                                                              ?.length ? (
                                                              <img
                                                                src={
                                                                  val2?.img_url
                                                                }
                                                                className="img-size"
                                                                alt={
                                                                  val2?.alt_tag
                                                                }
                                                                loading="lazy"
                                                              />
                                                            ) : (
                                                              ""
                                                            )}
                                                            {val2?.name}
                                                          </Link>
                                                        )}
                                                      </li>
                                                    )
                                                  )}
                                                </ul>
                                              </div>
                                            </div>
                                          ) : val1?.design == "2" ? (
                                            <div
                                              className={
                                                "rcs_diamonds_content dfL-border_rel bw-" +
                                                val1?.name?.replaceAll(" ", "-")
                                              }
                                            >
                                              <h2> {val1?.name} </h2>
                                              <div className="m-auto w-100 row">
                                                {val1?.submenu?.map((val2) => (
                                                  <div className="cust_padd_diamonds_shape col-lg-6">
                                                    <div className="rcs_diamonds_content_inner">
                                                      <Link to={val2?.slug}>
                                                        {val2?.img_url
                                                          ?.length ? (
                                                          <img
                                                            src={val2?.img_url}
                                                            alt={val2?.alt_tag}
                                                            loading="lazy"
                                                          />
                                                        ) : (
                                                          ""
                                                        )}
                                                        <p>{val2?.name}</p>
                                                      </Link>
                                                    </div>
                                                  </div>
                                                ))}
                                              </div>
                                            </div>
                                          ) : val1?.design == "3" ? (
                                            <div className="rcs_diamonds_content">
                                              <Link to={val1?.slug}>
                                                {val1?.img_url?.length ? (
                                                  <div className="text-center">
                                                    <img
                                                      src={val1?.img_url}
                                                      alt={val1?.alt_tag}
                                                      loading="lazy"
                                                      className={val1?.name === "certificate" ? "new_igi_img_desktop" : "img-fluid dfl_ddiamond_img"}
                                                      style={{
                                                        height: "100%",
                                                        objectFit: "cover",
                                                      }}
                                                    />
                                                  </div>

                                                ) : (
                                                  ""
                                                )}
                                              </Link>
                                            </div>
                                          ) : (
                                            ""
                                          )
                                        )}
                                        {index < 2 ? (
                                          <div className="dfl_menu_border"></div>
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                    </>
                                  ))}
                                </Row>
                              </Container>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              </Container>
            </div>
            {/* Header Section */}

            {hover == "1" && submenu?.submenu?.length ? (
              <div className="hover_overlayer"></div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* Mobile Menu Section */}
        <div className="rcs_menu_mobile d-none">
          <Container>
            <Row>
              <Drawer
                anchor="left"
                open={left}
                onClose={() => toggleDrawer(false)}
              >
                <div
                  role="presentation"
                // onKeyDown={() => toggleDrawer(false)}
                >
                  <div
                    className={
                      show === true ? "mobile-menu" : "mobile-menu nav-open"
                    }
                  >
                    {/*   <div className="mobile-auth-with-currency d-flex align-items-center justify-content-between p-2">
                      <div className="mobile-auth d-flex align-items-center justify-content-start">
                         <span onClick={() => dispatch(loginDrawer(true))}>Login / Signup</span>
                      </div>

                    <div className="">
                        <Dropdown
                          className={
                            "d-inline rcs_dropdown_lang " +
                            HeaderStyle.showcase_new_lang
                          }
                        >
                          <Dropdown.Toggle id="dropdown-autoclose-true">
                            <Image
                              src={currencyflag}
                              alt={currencycode}
                               loading="lazy"
                            ></Image>
                            <span>{currencycode}</span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {currencydata?.map((val) => (
                              <Dropdown.Item
                                onClick={() => {
                                  currencyHandle(
                                    val.currency_code,
                                    val.country_flag,
                                    val.currency_icon
                                  );
                                }}
                                href="#"
                              >
                                <Image
                                  src={val.country_flag}
                                  alt={val.currency_code}
                                ></Image>
                                <span> {val.currency_code}</span>
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>  
                    </div>*/}
                    <div className="inner-mobile-menu">
                      {/* <div className="rcs_search_bar mobileSearchBox px-2 mt-2">
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            history.push("/search/" + search_text);
                            window.scrollTo(0, 0);
                            setSearch_text("");

                            toggleDrawer(false);
                          }}
                        >
                        
                          <svg
                            onClick={(e) => {
                              history.push("/search/" + search_text);
                              setSearch_text("");

                            }}
                            width="16"
                            height="16"
                            style={{ opacity: "0.3" }}
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19.7112 18.3373L15.2347 13.8554C16.3899 12.3855 17.0878 10.5542 17.0878 8.55422C17.0878 3.83133 13.2611 0 8.54392 0C3.82671 0 0 3.83133 0 8.55422C0 13.2771 3.82671 17.1084 8.54392 17.1084C10.5656 17.1084 12.4188 16.4096 13.8628 15.2289L18.3393 19.7108C18.5319 19.9036 18.7726 20 19.0132 20C19.2539 20 19.4946 19.9036 19.7112 19.7108C20.0963 19.3253 20.0963 18.7229 19.7112 18.3373ZM1.92539 8.55422C1.92539 4.89157 4.88568 1.92771 8.54392 1.92771C12.2022 1.92771 15.1625 4.89157 15.1625 8.55422C15.1625 12.2169 12.2022 15.1807 8.54392 15.1807C4.88568 15.1807 1.92539 12.2169 1.92539 8.55422Z"
                              fill="var(--primary)"
                            ></path>
                          </svg>
                          <Form.Control
                            type="text"
                            placeholder="Search for.."
                            value={search_text}
                            onChange={(e) => setSearch_text(e.target.value)}
                          />
                         
                        </Form>
                      </div> */}

                      <Accordion defaultActiveKey="0">
                        {/* <Accordion.Item eventKey="0">
                                <Accordion.Header><Link to="#"><span className='auth'><img src={User} alt="" /></span> LOGIN/SIGNUP</Link></Accordion.Header>
                            </Accordion.Item> */}
                        {menudata?.map((val, index) => (
                          <Accordion.Item eventKey={index}>
                            <Accordion.Header
                              className={`${val.name === "Custom Design" ||
                                val.name === "Watches" || val.name === "Blog"
                                ? "no_button"
                                : ""
                                }`}
                            >
                              <Link
                                to={val.slug}
                                onClick={() => toggleDrawer(false)}
                              >
                                {val.name}
                              </Link>
                            </Accordion.Header>
                            <Accordion.Body className="position-relative">
                              {val?.submenu?.map((val1) =>
                                val1?.submenu?.map((val2) =>
                                  val2?.design == 3 ? (
                                    <div className="text-left mb-2">
                                      <img
                                        className={val2?.name === "certificate" ? "new_igi_class_mobile" : " w-100 mt-2"}
                                        onClick={() => history.push(val2?.slug)}
                                        src={val2?.img_url}
                                        alt={val2?.alt_tag}
                                        loading="lazy"
                                      />
                                    </div>

                                  ) : (
                                    <>
                                      <h2 className="title">{val2?.name}</h2>
                                      <ul
                                        className={
                                          val2?.design == 2
                                            ? "two-col-list"
                                            : "single-col-list"
                                        }
                                      >
                                        {val2?.submenu?.map((val3) => (
                                          <li>
                                            <Link
                                              onClick={() =>
                                                toggleDrawer(false)
                                              }
                                              to={val3.slug}
                                            >
                                              {val3?.img_url ? (
                                                <img
                                                  src={val3?.img_url}
                                                  alt={val3?.alt_tag}
                                                  loading="lazy"

                                                />
                                              ) : (
                                                ""
                                              )}
                                              {val3?.name}
                                            </Link>
                                          </li>
                                        ))}
                                      </ul>
                                    </>
                                  )
                                )
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}

                        <Accordion.Item eventKey="7" className="contactDetail">
                          <Accordion.Header>Contact us</Accordion.Header>
                          <div className="contact_detail">
                            <ul>
                              <li>
                                <a
                                  href='https://wa.me/+34649891997'
                                  target="_blank"
                                >
                                  <span>
                                    <RiWhatsappFill />
                                  </span>
                                  Whatsapp
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={() => {
                                    setShow(false);
                                    window.location.href = contact_number.link;
                                  }}
                                  href="#"
                                >
                                  <span>
                                    <BsFillTelephoneFill />
                                  </span>
                                  Call
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={(e) => {
                                    setShow(false);
                                    window.location.href = Email_details.link;
                                  }}
                                >
                                  <span>
                                    <IoMdMail />
                                  </span>
                                  Email
                                </a>
                              </li>
                              <li>
                                <Link
                                  onClick={() => setShow(false)}
                                  to="/contact"
                                >
                                  <span>
                                    <IoMdContact />
                                  </span>
                                  Contact
                                </Link>
                              </li>
                              {/* <li>
                                <Link
                                  onClick={() => setShow(false)}
                                  to="/contact"
                                >
                                  <span>
                                    <MdDateRange />
                                  </span>
                                  Book Appointment  
                                </Link>
                              </li> */}
                            </ul>
                          </div>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </Drawer>
            </Row>
          </Container>
        </div>
        {/* Mobile Menu Section */}
      </div>
    </>
  );
};
export default MegaMenu;
