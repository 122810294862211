import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import CustomImg from "../Assets/img/custom_section.webp";
import Heading from "../ui/Heading";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Je_welryspelling } from "../../../../Helpers/request";

const CustomDesign = () => {
  return (
    <section className="custom-design">
      <Container fluid className="home-showcase-container">
        <Row className="align-items-center">
          <Col md={6} xs={12} className="text-center">
            <Image src={CustomImg}  loading="lazy" alt="Custom Design Jewelry " className="img-fluid" />
          </Col>

          <Col md={6} xs={12}>
            <Heading>
              <span>Be The Designer</span>
              <h2>Design your custom   {Je_welryspelling}  </h2>
              <p>
                Indulge in the luxury of self-expression with our custom design  {Je_welryspelling}   service.
                From intricate details to personalized touches, each piece is crafted to reflect
                your unique style and story. Elevate your look with bespoke treasures that
                are as exceptional as you are.

              </p>
            </Heading>

            <div className="text-center">
              <Link to="/custom-design">
                <button className="golden-btn mx-auto">
                  Get Started
                </button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CustomDesign;
