import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "../../Assets/css/home.css";
import { isMobile, isMobileOnly } from "react-device-detect";
import HomeVideo from "../../../../Assets/images/home/video.mp4"
import { Skeleton } from "@mui/material";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { base_url, postHeader } from "../../../../Helpers/request";
import { useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
const BannerSlider = () => {
  const history = useHistory();
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //-----------------------------------Banner
    setLoading(true);
    axios
      .get(base_url + `/home/slider`, {
        headers: postHeader,
      })
      .then((response) => {
        if (response.data.status == 1) {
          setLoading(false);
          setBanner(response.data.data);
          localStorage.setItem(
            "bw-bannerdata",
            JSON.stringify(response.data.data)
          );
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      {/* {!loading ? (
        banner?.map((val, index) => (
          <div
            className="rcs_hero_slider"
            style={{
              backgroundImage: `url(${isMobileOnly ? val?.mobile_image : val?.image
                })`,
            }}
          >
            <div className="rcs_hero_img">
              <Container fluid>
                <Row className="m-auto w-100">
                  <Col md={6} className="p-0">
                    {val.btn_text === "" ? (
                      ""
                    ) : (
                      <div
                        className={`${
                          isMobile ? "text-center" : val.text_direction_class
                        } text-center`}
                      >
                        <h1 data-aos="fade-up-left" data-aos-duration="700">
                          {val.title}
                        </h1>
                        <p
                          data-aos="fade-up-left"
                          data-aos-duration="1200"
                          data-aos-delay="300"
                        >
                          {val.short_text}
                        </p>
                        <Button
                          data-aos="fade-up-left"
                          data-aos-duration="1800"
                          data-aos-delay="400"
                          onClick={() => {
                            history.push("/" + val.btn_link);
                          }}
                          className="outline-base-btn large"
                        >
                          {val.btn_text}
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>   
              </Container>
            </div>
          </div>
        ))
      ) : (
        <div className="sk" style={{ margin: "0px" }}>
          <Skeleton variant="text" animation="wave" height={"90vh"} />
        </div>
      )} */}
      <Container fluid className="rcs_banner_video">
        <Row>
          <Col xs="12" className="text-center video-box">
            <video autoPlay
              muted
              playsInline
              loop
              controls={false}
              style={{ width: "100%" }}>
              <source src="https://dl2vs6wk2ewna.cloudfront.net/media/video.spinosa.mp4" type="video/mp4" />
            </video>
            <div className="banner_text">
              <h1>Discover Timeless Elegance in Our Engagement Ring Collection. </h1>
              <p> Let’s Start With Forever </p>
              {/* <Link to="/ringsettings"> 
               <button className="golden-btn"> Shop Now </button>
              </Link> */}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BannerSlider;
